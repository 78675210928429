body {
  position: relative;
  width: 100%;
}

$bordersize: 4px;
$bordertext: 1px;
$bordermargin: 25px;

// #border {
//   height: calc(100vh - (#{$bordersize}*2) - (#{$bordermargin}*2));
//   width: calc(100% - (#{$bordersize}*2) - (#{$bordermargin}*2));
//   border: $bordersize solid $green;
//   margin: $bordermargin;
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 4;
//   &::before{
//     content: " ";
//     position: absolute;
//     z-index: 2;
//     top: calc(-#{$bordermargin} - #{$bordersize});
//     left: calc(-#{$bordermargin} - #{$bordersize});
//     right: calc(-#{$bordermargin} - #{$bordersize});
//     bottom: calc(-#{$bordermargin} - #{$bordersize});
//     border: calc(#{$bordermargin}) solid $white;
//   }
// }

.border {
  position: fixed;
  z-index: 10;
}

.border--topLeft {
  top:0;
  left: 0;
}

.border--bottomRight {
  bottom:0;
  right: 0;
}



#border1 {
  width: 100%;
  height: $bordermargin;
  background-color: $white;
}

#border2 {
  width: $bordermargin;
  height: 100vh;
  background-color: $white;
}

#border3 {
  width: 100%;
  height: $bordermargin;
  background-color: $white;
}

#border4 {
  width: $bordermargin;
  height: 100vh;
  background-color: $white;
}

#border5 {
  border-bottom: $bordersize solid $green;
  width: 100%;
  height: $bordermargin;
}

#border6 {
  border-left: $bordersize solid $green;
  width: $bordermargin;
  height: 100vh;
}

#border7 {
  border-top: $bordersize solid $green;
  width: 100%;
  height: $bordermargin;
}

#border8 {
  border-right: $bordersize solid $green;
  width: $bordermargin;
  height: 100vh;
}



section {
  padding: $bordermargin*2;
  z-index: 9;
  position: relative;
  &:first-of-type {
    h2 {
      margin: 3rem auto 0;
    }
  }
}

#posts--wrapper {
  min-height: 100vh;
}

#logo {
  height: calc(100vh - (#{$bordermargin}*4));
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1.5s opacity;
  opacity: 0;
  z-index: 9;
  &.active {
    opacity: 1;
  }
}

#logo_margarete {
  width: 62%;
  fill:$red;
  transition: 0.5s fill;
}


#descr {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 20rem;
  z-index: 3;
  position: relative;
  transition: 1.5s ease-in opacity, 2s ease-out transform;
  opacity: 0;
  transform: translate3d(0, 100px, 0) ;
  &.active {
  }
  p {
    max-width: 47rem;
    margin: 0 auto;
    color:$red;
    font-size: 1.4rem;
    line-height: 2.95rem;
    letter-spacing: 0.03em;
    &:first-of-type {
      margin-bottom: 3rem;
    }
  }
  &.active {
    opacity:1;
    transform: translate3d(0, 0, 0);
  }
}

#menu--home {
  margin-bottom: 15vh;
  #menu-main {
    li.current-menu-item {
      > a {
        border-bottom: none;
      }
    }
    li.current-menu-parent {
      > a {
        border-bottom: none;
      }
    }
  }

}

.separatorImg--wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  margin: 2rem 0 1.5rem;
  img {
    width: 150px;
    height: 100%;
  }
}

.next {
  text-align: center;
  margin: 5em 0 2em;
}

@include bp(mobile){

  $bordersize: 4px;
  $bordermargin: 10px;


  #border1 {
    height: $bordermargin;
    background-color: $white;
  }

  #border2 {
    width: $bordermargin;
    background-color: $white;
  }

  #border3 {
    height: $bordermargin;
  }

  #border4 {
    width: $bordermargin;
    background-color: $white;
  }

  #border5 {
    border-bottom: $bordersize solid $green;
    height: $bordermargin;
  }

  #border6 {
    border-left: $bordersize solid $green;
    width: $bordermargin;
  }

  #border7 {
    border-top: $bordersize solid $green;
    height: $bordermargin;
  }

  #border8 {
    border-right: $bordersize solid $green;
    width: $bordermargin;
  }

  section, footer {
    padding: $bordermargin*2;
  }


  #logo {
    height: calc(100vh - (#{$bordermargin}*2));

  }

  #logo_margarete {
      width: 80%;
  }

  #descr {
    margin-bottom: 10;
    padding: 30px;
    opacity: 1;
    p {
      font-size: 15px;
      line-height: 1.8em;

    }
  }
  #menu--home {
    margin-bottom: 0;
    #menu--wrapper {
        display: block;
    }
  }



}


@include bp(ipadV){

  #descr {
    padding: 100px;
    opacity: 1;
    p {
      font-size: 18px;
      line-height: 2em;
    }
  }

}

@include bp(ipadH){
  #descr {
    opacity: 1;
  }

}

@include bp(desktop){

}
