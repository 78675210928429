
$red: #E30613;
$white: white;
$green: #42832F;
$text: $red;
$black: #1a1a1a;

@mixin color_hover($baseColor) {
    color: $baseColor;
    &:hover  {
    	color: darken($baseColor, 13%);
    }
}
