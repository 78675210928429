#imagesWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200vh;
  > img {
    transition-property: transform;
    position: absolute;
    transition-timing-function: ease-in-out;
  }
}


#apfel {
  top: 57vh;
  left: -15vh;
  min-width: 500px;
  width: 45vw;
  z-index: 11;
  transition-duration:3s;
  transition-delay: 1s;
  transform: translate3d(-100%, 100%, 0) rotate(360deg);
  &.active {
    transform: translate3d(0, 0, 0) rotate(355deg)
  }
}

.biene {
  min-width: 130px;
  width: 10vw;
}

#biene {
  top: 20vh;
  left: 10vw;
  transition-duration:3s;
  transition-delay: 1.5s;
  transform: translate3d(100vw, 100vh, 0) rotate(300deg);
  &.active {
    transform: translate3d(0, 0, 0) rotate(300deg);
  }
}

#himbeere {
  top: 5vh;
  right: 0;
  min-width: 700px;
  width: 50%;
  transition-duration:2s;
  transition-delay: 0.5s;
  transform: translate3d(100%, 0, 0) rotate(360deg);
  &.active {
    transform: translate3d(0, 0, 0) rotate(340deg)
  }
}

#messer {
  top: 35%;
  right: 2rem;
  width: 100px;
  transition-duration:2s;
  transform: rotate(200deg) translate3d(0, 100%, 0) ;
  &.active {
    transform: rotate(300deg) translate3d(0, 0, 0);

  }
}

#gabel {
  top: 55%;
  left: 50px;
  transition-duration:2s;
  width: 150px;transform: rotate(0) translate3d(-130%, 100%, 0);
  &.active {
    transform: rotate(60deg) translate3d(0, 0, 0);

  }
}

#biene2 {
  top: 63%;
  right: 30vw;
  transition-duration:2s;
  transform: translate3d(-50vw, 100vh, 0) rotate(40deg);
  &.active {
    transform: translate3d(0, 0, 0) rotate(40deg);
  }
}

#pilzfull {
  align-self: flex-end;
  position: relative;
  width: 100%;
  min-width: 1500px;
  transition-duration:2s;
  > img {

    z-index: 11;
    position: relative;
    width: 40%;
    bottom: -40px;
    position: absolute;
    &:first-of-type {
      left: 0;
    }
    &:last-of-type {
      right: 0;
    }
  }
}


@include bp(mobile){
  #himbeere, #apfel {
      min-width: inherit;
      width: 80vw;
  }
  #gabel {
      min-width: inherit;
      width: 30vw;
      display: none;
  }
  #messer {
      min-width: inherit;
      width: 20vw;
      display: none;
  }
  #biene {
    top: 25vh;
  }
  #biene2 {
    top: 64%;
  }

  .biene {
    min-width: inherit;
    width: 30vw;
  }

  #pilzfull {
    > img {
      width: 47%;
    }
  }

}
