header {
  z-index: 3;
  position: relative;
}

  #logo--wrapper {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    margin: 100px 0 50px;
    @include bp(mobile) {
      justify-content: flex-start;
      margin: 11px 30px;
      svg {
        height: 100px;
      }
    }
    a {
      width: 80%;
      max-width: 30rem;
      svg {
        width: 100%;
      }
    }
  }


#menu--wrapper {
  margin-bottom: 40px;
  @include bp(mobile) {
    display: none;
  }
  ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;

    @include bp(mobile) {
      flex-wrap: wrap;
      text-align: center;
      margin-bottom: 1rem;
    }
    @include bp(ipadV) {
      flex-wrap: wrap;
    }
    li{
      margin: 2rem;
      @include bp(mobile) {
        flex: 1 100%;
        margin: 0.5rem 1rem;
      }
      @include bp(ipadV) {
        margin: 0.5rem 1rem;
      }
      a {
        color: $red;
        font-size: 1rem;
        letter-spacing: 0.16em;
        line-height: 1.8em;
        padding-bottom: 0.25em;
        &:hover {
          border-bottom: $bordertext solid $green;
        }
      }

    }
  }
}

#menu--wrapper.blog {
  #menu-main {
    > li  {


      &:first-of-type {
        > a {
          border-bottom: $bordertext solid $green;
        }
        > ul {

          display: flex;
        }
      }
    }
  }
}

#menu-main {
  > li {
    > a {
      z-index: 2;
      position: relative;
      text-transform: uppercase;
    }
    > ul {
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      height: 70px;
    }
    &:hover {
      > ul {
        z-index: 1;
        display: flex;
        background-color:$white;
      }
    }
    &.current-menu-parent, &.current-menu-item {
      > ul {
        display: flex;
      }
    }


  }
  li.current-menu-item {
    > a {
      border-bottom: $bordertext solid $green;
    }
  }
  li.current-menu-parent,{
    > a {
      border-bottom: $bordertext solid $green;
    }
  }

}

@include bp(mobile){

  #burger--wrapper {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px 30px;
  }

  #menu-main {
    > li {
      > ul {
        position: relative;
        height: inherit;
        margin-top: 1em;
      }
    }
  }
}

@include bp(ipadV){
  #burger--wrapper {
    display: none;
  }
}

@include bp(ipadH){
  #burger--wrapper {
    display: none;
  }
}

@include bp(desktop){
  #burger--wrapper {
    display: none;
  }
}
