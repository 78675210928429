

// EXAMPLE

@font-face {
    font-family: 'Quicksand-Light';
    src: url('../margarete/assets/fonts/quicksand-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Medium';
    src: url('../margarete/assets/fonts/quicksand-medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Keyline-Regular';
    src: url('../margarete/assets/fonts/keyline-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$fqsl: 'Quicksand-Light';
$fqsm: 'Quicksand-Medium';
$fkr: 'Keyline-Regular';
