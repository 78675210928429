.postcontent--Wrapper {
  width: 100%;
  > div, h5, p, img {
    clear: both;
  }
  .recipe--ingr {
    text-align: center;
  }
  .tags {
    width: 100%;
    padding-top: 1rem;
  }
  h4 {
    margin: 1.5rem 0;
  }
  .recipe--tags {
    margin: 3rem 0;
  }
  .postcontent {
    > img {
      @include object-fit(cover);
      margin: 1%;
      &.aligncenter {
        width: calc(100% - 2%);
      }
      &.alignnone {
        width: 48%;
      }
    }
    > * {
      width: 100%;
    }
    h5 {
      width: auto;
      margin: 3rem auto 1.5rem;
      text-align: center;
      position: relative;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      &:before {
        content:"";
        height: $bordertext;
        width: 3rem;
        background-color: $red;
        margin-top: 0.8em;
        margin-right: 2em;
      }
      &:after {
        content:"";
        height: $bordertext;
        width: 3rem;
        background-color: $red;
        margin-top: 0.8em;
        margin-left: 2em;
      }
    }
    p {
      max-width: 45em;
      margin: 1rem auto;
      color: $black;
      font-weight: 500;
      text-align: justify;
    }
  }
}
.post--excerpt {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    max-width: 40em;
    margin: 1rem 0;
  }
  .date{
    &:before {
      content:"";
      position: absolute;
      left: -4.5rem;
      height: $bordertext;
      width: 3rem;
      background-color: $red;
    }
    &:after {
      content:"";
      position: absolute;
      right: -4.5rem;
      height: $bordertext;
      width: 3rem;
      background-color: $red;
    }
  }
}
.post--excerpt, .post--half {
  h3 {
    margin: 1rem 0;
    text-align: center;
  }
  .tags, .recipe--tags {
    ul {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      li {
        @include bp(mobile) {
          flex: 1 100%;
        }
        font-size: 0.7rem;
        margin:0.25rem 1rem;
      }
    }
  }
  .tags {
    ul {
      li {
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }
  .date {
    position: relative;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
  }
  .date {
    text-transform: uppercase;
  }
  .mainImg-wrapper {
    height: 300px;
    width: 40em;
    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }
  .excerpt {
    text-align: center;
    text-transform: uppercase;
    max-width: 29em;
  }
  .articleLink, h4 {
    padding-top: 1rem;
    border-top: $bordertext solid $red;
    display: inline-block;
  }
}

.post--half {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  height: 50vh;
  max-height: 25rem;
  @include bp(mobile) {
    height: auto;
    max-height: none;
    flex-direction: column;
  }
 > div {
   flex: 1 50%;
   @include bp(mobile) {
     height: 250px;
   }
   &:first-of-type {
     padding: 1rem;
     display: -webkit-flex;
     display: -ms-flex;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

     > div {
       margin: 1rem 0;
     }
   }
   &:last-of-type {
     img {
       height: 100%;
       width: 100%;
       @include object-fit(cover);
     }
    }
  }
  .date {
    padding-bottom: 1rem;
    border-bottom: $bordertext solid $red;
  }
  .excerpt {
    padding: 0 1.5rem;
  }
}
.rev {
  flex-direction: row-reverse;
}
