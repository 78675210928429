
footer {
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 0.08em;
  color:$green !important;
  padding: 25vh $bordermargin*2 $bordermargin*2;
  z-index: 9;
  position: relative;
  h2 {
    letter-spacing: 0;
  }
  > div {
    margin: 2rem 0 1rem;
  }
  p {
    margin: 0 0 0.7rem;
    &:first-of-type {
      margin-top: 2rem;
    }
    a {
      padding: 0;
    }
  }
  a {
    z-index: 12;
    position: relative;
    padding: 1rem;
    color:$green !important;
  }
}


@include bp(mobile){
  footer {
    padding: $bordermargin*2 22vw;
    > div {
      margin: 2rem 0 1rem;
    }

    a {
      display: block;
      padding: 0.5rem;
    }
  }
}
